import { useEffect } from 'react';

const useActiveCampaignForm = (formId) => {
  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement('script');
      script.src = `https://adamfard48700.activehosted.com/f/embed.php?id=${formId}`;
      script.async = true;

      const formWrapper = document.getElementById('activecampaign-form__wrapper');
      formWrapper.appendChild(script);

      return () => {
        formWrapper.removeChild(script);
      };
    };

    if (document.readyState === 'complete') {
      loadScript();
    } else {
      window.addEventListener('load', loadScript);
      return () => {
        window.removeEventListener('load', loadScript);
      };
    }
  }, [formId]);
};

export default useActiveCampaignForm;
