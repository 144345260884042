import React from "react";
import { Helmet } from "react-helmet";

export const ContactJsonLd = () => {
  const mainjson = {
    "@context": "https://schema.org",
    "@type": "ContactPage",
    url: "https://adamfard.com/contact-us",
    publisher: "https://adamfard.com/",
    name: "Get in touch to scale your startup",
    image: [
      "https://www.cornerstoneprotection.com/wp-content/uploads/2020/08/contact-us-cornerstone-protection.jpg",
      {
        "@type": "ImageObject",
        "@id":
          "https://adamfard.com/static/c3ead8266a0ba7e8c3e2d7638a3b4941/58556/ux-design-agency-3.webp",
        url:
          "https://adamfard.com/static/c3ead8266a0ba7e8c3e2d7638a3b4941/58556/ux-design-agency-3.webp",
        width: "300",
        height: "105"
      }
    ],
    description:
      "Want to improve usability in your product? Want to engage users at a higher level and outperform your competition?",
    about: {
      "@type": "ContactPoint",
      hoursAvailable: {
        "@type": "OpeningHoursSpecification",
        closes: "24/7",
        dayOfWeek: ["Friday", "Monday", "Thursday", "Tuesday", "Wednesday"],
        name: "Adarm Fard Hours",
        opens: "08:00:00",
        "@id":
          "https://adamfard.com/db/Ugozi_Edge_Inc/OpeningHoursSpecification_0"
      },
      availableLanguage: [
        {
          "@type": "Language",
          "@id": "https://adamfard.com/db/Ugozi_Edge_Inc/English",
          name: "English"
        }
      ],
      description:
        "Want to improve usability in your product?. Want to engage users at a higher level and outperform your competition?. Want to reduce the onboarding time and improve sales?",
      contactType: "customer service",
      email: "contact@adamfard.com",
      name: "Sales Inquiries",
      url: "https://adamfard.com/contact/#sales",
      areaServed: {
        "@type": "Place",
        name: "Earth",
        "@id": "https://en.wikipedia.org/wiki/Earth"
      },
      "@id": "https://adamfard.com/contact/#sales"
    },
    "@id": "https://adamfard.com/contact-us"
  };

  const webpageJson = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://adamfard.com/contact-us/#Webpage",
    url: "https://adamfard.com/contact-us/",
    headline: "Get in touch to scale your startup",
    primaryImageOfPage: [
      "https://adamfard.com/static/c3ead8266a0ba7e8c3e2d7638a3b4941/58556/ux-design-agency-3.webp",
      "https://goodfirms.s3.amazonaws.com/badges/color-badge/mobile-app-design-companies.svg",
      "https://adamfard.com/static/b842ac2913e8c5015e32dc7c77714589/1fe9e/Top_B2B_Western_Europe.webp"
    ],
    lastReviewed: "2020/05/09",
    relatedLink: ["https://en.wikipedia.org/wiki/Usability_testing"],
    significantLink: "https://adamfard.com/contact-us",
    description:
      "Want to improve usability in your product? Want to engage users at a higher level and outperform your competition? ",
    image:
      "https://adamfard.com/static/c3ead8266a0ba7e8c3e2d7638a3b4941/58556/ux-design-agency-3.webp",
    sameAs: ["https://en.wikipedia.org/wiki/User_interface"],
    about: [
      {
        "@type": "thing",
        name: "User interface design",
        sameAs: ["https://en.wikipedia.org/wiki/User_interface_design"]
      },
      {
        "@type": "thing",
        name: "Usability testing",
        sameAs: "https://en.wikipedia.org/wiki/Usability_testing"
      },
      {
        "@type": "thing",
        name: "User experience design",
        sameAs: "https://en.wikipedia.org/wiki/User_experience_design"
      }
    ]
  };

  const breadCrumbJson = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    numberOfitems: "2",
    description:
      "Want to improve usability in your product? Want to engage users at a higher level and outperform your competition?",
    disambiguatingDescription:
      "Do you want to make your product more usable? Want to increase customer engagement and outperform your competitors?",
    mainEntityOfPage: "https://adamfard.com/contact-us/",
    image:
      "https://adamfard.com/static/c3ead8266a0ba7e8c3e2d7638a3b4941/58556/ux-design-agency-3.webp",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: { "@id": "https://adamfard.com/", name: "Adam Fard" }
      },
      {
        "@type": "ListItem",
        position: 2,
        item: { "@id": "https://adamfard.com/contact-us/", name: "Contact Us" }
      }
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(mainjson)}</script>
      <script type="application/ld+json">{JSON.stringify(webpageJson)}</script>
      <script type="application/ld+json">
        {JSON.stringify(breadCrumbJson)}
      </script>
    </Helmet>
  );
};
